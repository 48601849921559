import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { firstBy } from 'thenby';
import '../Utils';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker, DateTimePicker, PickersDay } from '@mui/x-date-pickers';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StoreIcon from '@mui/icons-material/Store';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

/**
 * Dynamic dialog components
 */
import ProgramDaySchedules from './Core/ProgramDaySchedules';
import ServiceActionText from './Core/ServiceActionText';
import ServiceActionCheckbox from './Core/ServiceActionCheckbox';
import ServiceActionFile from './Core/ServiceActionFile';
import ServiceActionOption from './Core/ServiceActionOption';

const schedules = [
  {
    idDoctorContract: '1',
    scheduleDate: '2024-10-31',
    scheduleTime: '15:25',
    scheduleDuration: '15',
    name: 'Name1',
  },
  {
    idDoctorContract: '1',
    scheduleDate: '2024-10-31',
    scheduleTime: '16:15',
    scheduleDuration: '50',
    name: 'Name2',
  },
  // {
  //   idDoctorContract: '1',
  //   scheduleDate: '2024-10-31',
  //   scheduleTime: '17:15',
  //   scheduleDuration: '45',
  //   name: 'Name3',
  // },
  {
    idDoctorContract: '1',
    scheduleDate: '2024-10-31',
    scheduleTime: '12:45',
    scheduleDuration: '40',
    name: 'Name4',
  },
  {
    idDoctorContract: '1',
    scheduleDate: '2024-10-31',
    scheduleTime: '14:20',
    scheduleDuration: '20',
    name: 'Name5',
  },
  {
    idDoctorContract: '1',
    scheduleDate: '2024-10-31',
    scheduleTime: '13:30',
    scheduleDuration: '15',
    name: 'Name6',
  },
  {
    idDoctorContract: '1',
    scheduleDate: '2024-10-31',
    scheduleTime: '14:50',
    scheduleDuration: '30',
    name: 'Name7',
  },
  {
    idDoctorContract: '1',
    scheduleDate: '2024-10-31',
    scheduleTime: '15:45',
    scheduleDuration: '25',
    name: 'Name8',
  },
  {
    idDoctorContract: '1',
    scheduleDate: '2024-10-31',
    scheduleTime: '13:55',
    scheduleDuration: '20',
    name: 'Name9',
  },
  {
    idDoctorContract: '1',
    scheduleDate: '2024-10-31',
    scheduleTime: '12:00',
    scheduleDuration: '15',
    name: 'Name10',
  },
  {
    idDoctorContract: '2',
    scheduleDate: '2024-10-31',
    scheduleTime: '15:25',
    scheduleDuration: '15',
    name: 'Name1',
  },
  // {
  //   idDoctorContract: '2',
  //   scheduleDate: '2024-10-31',
  //   scheduleTime: '16:15',
  //   scheduleDuration: '45',
  //   name: 'Name2',
  // },
  {
    idDoctorContract: '2',
    scheduleDate: '2024-10-31',
    scheduleTime: '14:20',
    scheduleDuration: '20',
      name: 'Name5',
  },
  {
    idDoctorContract: '2',
    scheduleDate: '2024-10-31',
    scheduleTime: '14:00',
    scheduleDuration: '20',
    name: 'Name6',
  },
  {
    idDoctorContract: '2',
    scheduleDate: '2024-10-31',
    scheduleTime: '14:50',
    scheduleDuration: '30',
    name: 'Name7',
  },
  {
    idDoctorContract: '2',
    scheduleDate: '2024-10-31',
    scheduleTime: '15:45',
    scheduleDuration: '25',
    name: 'Name8',
  },
];


function getBadgeColor(scheduled, program) {
  const percentage = (scheduled / program) * 100;
  let red = 0, green = 0, blue = 0;

  if (percentage <= 33) {
    // De la verde la orange (0%-33%)
    red = Math.floor((percentage / 33) * 255); // crește roșul
    green = 255; // verde rămâne constant
  } else if (percentage <= 66) {
    // De la orange la galben (33%-66%)
    red = 255; // roșu rămâne constant
    green = Math.floor(((66 - percentage) / 33) * 255); // scade verdele
  } else {
    // De la galben la roșu (66%-100%)
    red = 255; // roșu rămâne constant
    green = 0; // verdele este zero
  }

  return `rgb(${red}, ${green}, ${blue})`;
}

const ServiceActionsBeforeOpen = props => {
  //#region Constants
  //#endregion

  //#region States
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [defs, setDefs] = useState(null);
  // const [isReady, setIsReady] = useState(false);
  const [notification, setNotification] = useState(null);
  //#endregion

  //#region Hooks
  const ac = useContext(AppContext);
  //#endregion

  //#region Effects
  useEffect(() => {
    ac.ajax('getReceptionServiceActionValues', {
      idBranch: props.deps.idBranch,
      idServices: props.value.map(reception => reception.services.map(service => service.idService)).flat(),
      idDoctorContracts: props.value.map(reception => reception.idDoctorContract).filter(idDoctorContract => Boolean(idDoctorContract)),
      idBeneficiaryContracts: props.value.map(reception => reception.idBeneficiaryContract).filter(idBeneficiaryContract => Boolean(idBeneficiaryContract)),
    })
      .then(response => {
        if (response.status.ok) {
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          props.onLoad({
            target: {
              type: 'component',
              data: {
                receptionServiceActionValues: response.result.data.receptionServiceActionValues,
                childServices: response.result.dependencies.servicePackServices,
              },
            },
          });

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isLoaded) return;

    if (data === null && JSON.stringify(data) !== JSON.stringify(props.value)) {
      setDefaultData(props.value);
      setData(props.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!isLoaded || data === null) return;

    let message = null;

    message = message !== null ? message : data.every(reception =>
      reception.services.every(service =>
        !isNaN(service.price)
      )
    ) ? message : `Un pret este completat incorect`;
    message = message !== null ? message : data.every(reception =>
      reception.services.every(service =>
        deps.serviceActions.filter(action => action.requiredStates.includes(getReceptionState(reception)) && action.idService === service.idService).every(serviceAction =>
          Boolean(service.receptionServiceActionValues.find(receptionServiceActionValue => receptionServiceActionValue.idServiceAction === serviceAction.id)?.value)
        )
      )
    ) ? message : `Un camp marcat ca obligatoriu este necompletat`;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, defaultData, data]);

  useEffect(() => {
    if (!isLoaded) return;

    data.forEach((reception, index) => {
      reception.services.forEach(service => {
        if (service.isAutoPrice) {
          const price = getServicePrice(reception, service);

          if (service.price === service.priceDefault) {
            if (service.price !== price) {
              handleDataChange([index, 'services', {idService: service.idService}])({target: {data: {price: price, priceDefault: price}}});
            }
          } else {
            handleDataChange([index, 'services', {idService: service.idService}])({target: {data: {priceDefault: price}}});
          }
        }
      });
    });
  }, [data]);

  useEffect(() => {
    if (!isLoaded) return;

    if (JSON.stringify(data) !== JSON.stringify(props.value)) {
      props.onChange({
        target: {
          type: 'component',
          data: data,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  //#endregion

  //#region Getters
  const getReceptionsPrice = useCallback(() => data.reduce((acc, reception, index) => acc + +getReceptionPrice(index), 0), [data]);
  const getReceptionPrice = useCallback(index => data[index].services.reduce((acc, service) => acc + +service.price, 0), [data]);
  const getServicePrice = useCallback((reception, service) => {
    return service.childServices.filter(childService => false
      || (!childService.isRemovable && !childService.isAddable)
      || (childService.isRemovable && !childService.isRemoved)
      || (childService.isAddable && childService.isAdded)
    ).map(childService => getChildServicePrice(reception, service, childService)
    ).reduce((acc, val) => acc + (parseFloat(val) || 0), 0).toCurrency('', '', '.', 2);
  }, [data]);
  const getChildServicePrice = useCallback((reception, service, childService) => {
    let childServicePrice;
    childServicePrice = props.deps.servicePrices.find(servicePrice => true
      && servicePrice.idBeneficiary === service.idBeneficiary
      && servicePrice.idBranch === reception.idBranch
      && servicePrice.idBeneficiaryContract === props.deps.servicePrices.find(item => item.id === service.idServicePrice).idBeneficiaryContract
      && servicePrice.idDoctorContract === props.deps.servicePrices.find(item => item.id === service.idServicePrice).idDoctorContract
      && servicePrice.idService === childService.idService
      && servicePrice.idDatasetValue === props.deps.servicePrices.find(item => item.id === service.idServicePrice).idDatasetValue
    );

    if (childServicePrice !== undefined) {
      return childServicePrice.price;
    } else {
      childServicePrice = props.deps.servicePrices.find(servicePrice => true
        && servicePrice.idBeneficiary === service.idBeneficiary
        && servicePrice.idBranch === reception.idBranch
        && servicePrice.idBeneficiaryContract === props.deps.servicePrices.find(item => item.id === service.idServicePrice).idBeneficiaryContract
        && servicePrice.idDoctorContract === props.deps.servicePrices.find(item => item.id === service.idServicePrice).idDoctorContract
        && servicePrice.idService === childService.idService
        && servicePrice.idDatasetValue === ''
      );

      if (childServicePrice !== undefined) {
        return childServicePrice.price;
      } else {
        return null;
      }
    }
  }, [data]);
  const getServiceAction = useCallback(idServiceAction => deps.serviceActions.find(item => item.id === idServiceAction), [data]);
  const getReceptionState = reception => {
    switch (reception.type) {
      case 'booked': return 'beforeBooking';
      case 'scheduled': return 'beforeSchedule';
      case 'opened': return 'beforeOpen';
      default: return null;
    }
  };
  const getReceptionServiceActionValues = (reception, service) => service.receptionServiceActionValues.sort(firstBy('position'));
  const getDoctorDays = useCallback(idDoctorContract => deps?.doctorPrograms.filter(doctorProgram => doctorProgram.idDoctorContract === idDoctorContract).map(doctorProgram => doctorProgram.day) || [], [deps]);
  const getDoctorProgram = (idDoctorContract, day) => deps?.doctorPrograms.find(doctorProgram => doctorProgram.idDoctorContract === idDoctorContract && doctorProgram.day === day) || null;
  const getValidScheduleTimes = (program, scheduleDuration, schedules) => {
    const validTimes = [];

    const programStart = dayjs(`${program.day}T${program.timeStart}`);
    const programStop = dayjs(`${program.day}T${program.timeStop}`);

    let currentStart = programStart;

    while (currentStart.isBefore(programStop)) {
      const currentStop = currentStart.add(scheduleDuration, 'minute');

      if (currentStop.isAfter(programStop) || currentStart.isBefore(dayjs())) {
        currentStart = currentStart.add(5, 'minute');
        break;
      }

      let isValid = true;

      for (const schedule of schedules) {
        const scheduleStart = dayjs(`${schedule.scheduleDate}T${schedule.scheduleTime}`);
        const scheduleStop = scheduleStart.add(schedule.scheduleDuration, 'minute');

        if (
          currentStart.isBetween(scheduleStart, scheduleStop, null, '[)') ||
          currentStop.isBetween(scheduleStart, scheduleStop, null, '(]') ||
          scheduleStart.isBetween(currentStart, currentStop, null, '[)') ||
          scheduleStop.isBetween(currentStart, currentStop, null, '(]')
        ) {
          isValid = false;
          break;
        }
      }

      if (isValid) {
        validTimes.push(currentStart.format('HH:mm'));
      }

      currentStart = currentStart.add(5, 'minute');
    }

    return validTimes;
  };

  const getReceptionError = reception => {
    let error = null;

    error = error !== null ? error : reception.services.every(service => !isNaN(service.price)) ? error : `Un pret este completat incorect`;
    error = error !== null ? error : reception.services.every(service =>
      deps.serviceActions.filter(action => action.requiredStates.includes(getReceptionState(reception)) && action.idService === service.idService).every(serviceAction =>
        Boolean(service.receptionServiceActionValues.find(receptionServiceActionValue => receptionServiceActionValue.idServiceAction === serviceAction.id)?.value)
      )
    ) ? error : `Un camp marcat ca obligatoriu este necompletat`;

    return error;
  };
  //#endregion

  //#region Handlers
  const handleDataChange = path => evt => {
    const deepMergeByPath = (obj, path) => {
      if (path.length === 0) {
        return evt.target.data
          ? {
              ...obj,
              ...evt.target.data
            }
          : {
              ...obj,
              [evt.target.name]: evt.target.value
            };
      }

      const [current, ...restPath] = path;

      switch (typeof current) {
        case 'number': // Search by array index
          return [
            ...obj.slice(0, current),
            deepMergeByPath(obj[current], restPath),
            ...obj.slice(current + 1)
          ];
        case 'string': // Search by object property
          return {
            ...obj,
            [current]: deepMergeByPath(obj[current], restPath)
          };
        case 'object': // Search by array element value
          const index = obj.findIndex(item => Object.entries(current).every(([key, value]) => item[key] === value));

          if (index !== -1) {
            return [
              ...obj.slice(0, index),
              deepMergeByPath(obj[index], restPath),
              ...obj.slice(index + 1)
            ];
          } else {
            throw new Error('Elementul nu a fost găsit în array.');
          }
        default:
          throw new Error(`Tipul elementului curent din cale este invalid si nu este tratat (tip: '${typeof current}').`);
      }
    };

    setData(prevState => deepMergeByPath(prevState, path));
    // setIsReady(false);
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleReceptionSave = receptionIndex => () => {
    ac.ajax('saveReception', {
      ...props.deps.customer,
      ...data[receptionIndex],
      price: getReceptionPrice(receptionIndex).toString().toCurrency('', '', '.'),
    })
      .then(response => {
        if (response.status.ok) {
          setData(prevState => [
            ...prevState.slice(0, receptionIndex),
            response.result.data.reception,
            ...prevState.slice(receptionIndex + 1)
          ]);
        }
      });
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveReceptions', {
      receptions: data.map(reception => ({
        ...props.deps.customer,
        ...reception,
      })),
    })
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };
  //#endregion

  /**
   * Renderer
   */
  if (!isLoaded || data === null) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='lg' fullWidth open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {
      //#region Dialog title
      }
      <DialogTitle component='div' sx={{display: 'flex'}}>
        <Typography variant='h6' sx={{flexGrow: 1}}>Completare date necesare deschiderii serviciilor selectate</Typography>
        <Typography variant='h6' sx={{pr: 2, color: theme => theme.palette.primary.main}}>{getReceptionsPrice().toString().toCurrency()}</Typography>
      </DialogTitle>
      {
      //#endregion
      }

      {
      //#region Dialog content
      }
      <DialogContent sx={{px: 2, maxHeight: '70vh', overflowY: 'scroll'}}>
        {data.map((reception, index) => (
          <DialogContentText key={index} sx={{mt: .5, mb: 1.5, pt: 2, pl: 2, pr: 1, pb: 1, outline: '1px solid lightgray', borderRadius: '4px', boxShadow: '5px 5px 5px 0px lightgray', overflow: 'hidden'}}>
            <Grid container spacing={1}>
              {
              //#region Reception header
              }
              <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.06)'}}>
                <Typography variant='subtitle1' sx={{fontWeight: 'bold', color: theme => theme.palette.primary.main, whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}}>
                  {reception.branch}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.06)'}}>
                <Typography variant='subtitle1' sx={{fontWeight: 'bold', color: theme => theme.palette.primary.main, whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}}>
                  {reception.specialty}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.06)'}}>
                <Typography variant='subtitle1' sx={{fontWeight: 'bold', color: theme => theme.palette.primary.main, whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}}>
                  {reception.doctor}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{justifyContent: 'flex-end', backgroundColor: 'rgba(0, 0, 0, 0.06)'}}>
                <ToggleButtonGroup exclusive color='secondary' size='small'
                  value={reception.type}
                  onChange={(evt, value) => handleDataChange([index])({target: {type: 'component', name: 'type', value: value || reception.type}})}
                >
                  <Tooltip title='Adauga in lista de asteptare'>
                    <Box>
                      <ToggleButton value='booked'
                        disabled={ac.isLoading || !reception.services.reduce((acc, service) => acc && service.isBookable, true)}
                      >
                        <NotificationsIcon />
                      </ToggleButton>
                    </Box>
                  </Tooltip>
                  <Tooltip title='Deschide o programare'>
                    <Box>
                      <ToggleButton value='scheduled'
                        disabled={ac.isLoading || !reception.services.reduce((acc, service) => acc && service.isSchedulable, true)}
                      >
                        <ScheduleIcon />
                      </ToggleButton>
                    </Box>
                  </Tooltip>
                  <Tooltip title='Deschide serviciile'>
                    <Box>
                      <ToggleButton value='opened'
                        disabled={ac.isLoading}
                      >
                        <AttachMoneyIcon />
                      </ToggleButton>
                    </Box>
                  </Tooltip>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={2} sx={{justifyContent: 'flex-end', backgroundColor: 'rgba(0, 0, 0, 0.06)'}}>
                <Typography variant='subtitle1' sx={{pr: 0, fontWeight: 'bold', color: theme => theme.palette.info.main, whiteSpace: 'nowrap'}}>
                  {getReceptionPrice(index).toString().toCurrency()}
                </Typography>
              </Grid>
              {reception.type === 'scheduled' &&
                <Grid item xs={12} sx={{backgroundColor: 'rgba(0, 0, 0, 0.06)'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={8} sx={{alignItems: 'stretch'}}>
                      {getDoctorProgram(reception.idDoctorContract, reception.scheduleDate) &&
                        <ProgramDaySchedules
                          program={getDoctorProgram(reception.idDoctorContract, reception.scheduleDate)}
                          schedules={schedules.filter(schedule => schedule.idDoctorContract === reception.idDoctorContract && schedule.scheduleDate === reception.scheduleDate)}
                          newSchedule={{
                            scheduleDate: reception.scheduleDate,
                            scheduleTime: reception.scheduleTime,
                            scheduleDuration: reception.scheduleDuration,
                          }}
                        />
                      }
                    </Grid>
                    <Grid item xs={3}>
                      {reception.scheduleDuration === '0'
                        ? <DatePicker disableHighlightToday disablePast showDaysOutsideCurrentMonth={false} skipDisabled views={['year', 'month', 'day'/*, 'hours', 'minutes'*/]}
                            label='Data programarii'
                            shouldDisableYear={date => {
                              return !deps.branchPrograms.find(branchProgram => true
                                // && doctorProgram.idDoctorContract === reception.idDoctorContract
                                && dayjs(branchProgram.day).year() === /*dayjs(*/date/*)*/.year()
                              );
                            }}
                            shouldDisableMonth={date => {
                              return !deps.branchPrograms.find(branchProgram => true
                                // && branchProgram.idDoctorContract === reception.idDoctorContract
                                && dayjs(branchProgram.day).year() === dayjs(date).year()
                                && dayjs(branchProgram.day).month() === /*dayjs(*/date/*)*/.month()
                              );
                            }}
                            shouldDisableDate={date => !getDoctorDays(reception.idDoctorContract).includes(dayjs(date).format('YYYY-MM-DD'))}
                            slots={{
                              day: props => {
                                const program = deps.branchPrograms.find(branchProgram => branchProgram.day === props.day.format('YYYY-MM-DD'));
                                const scheduled = program ? program.scheduledDuration : 0;
                                const total = program ? program.programDuration : 1;

                                return (
                                  <Tooltip
                                    title={
                                      program
                                        ? <div>
                                            <div>{`Timp ocupat: ${(scheduled / total * 100).toFixed()}%`}</div>
                                            <div style={{position: 'relative', width: '100%', height: '16px', background: 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(255,255,0,1) 50%, rgba(255,0,0,1) 100%)', borderRadius: '4px', overflow: 'hidden'}}>
                                              <div style={{position: 'absolute', top: 0, right: 0, width: `${100 - Math.min(Math.max((scheduled / total) * 100, 0), 100)}%`, height: '100%', background: 'lightgray'}} />
                                            </div>
                                            {program.description &&
                                              <Typography variant='caption'>{program.description}</Typography>
                                            }
                                          </div>
                                        : null
                                    }
                                  >
                                    <Box>
                                      <PickersDay key={props.day.toString()} {...props} outsideCurrentMonth={props.outsideCurrentMonth} day={props.day}
                                        sx={{
                                          fontWeight: 'bold',
                                          borderWidth: '4px',
                                          borderStyle: 'solid',
                                          borderColor: program ? getBadgeColor(program?.scheduledDuration, program?.programDuration) : 'transparent',
                                        }}
                                      />
                                    </Box>
                                  </Tooltip>
                                );
                              },
                            }}
                            slotProps={{
                              textField: {
                                variant: 'outlined',
                                InputProps: {
                                  readOnly: true,
                                  disabled: true,
                                },
                              },
                              field: {
                                clearable: true,
                                onClear: () => handleDataChange([index])({target: {data: {scheduleDate: null, scheduleTime: null}}}),
                              },
                            }}
                            value={reception.scheduleDate && reception.scheduleTime ? dayjs(`${reception.scheduleDate}T${reception.scheduleTime}`) : null}
                            onChange={newValue => {
                              // console.log('onChange:', newValue ? newValue.format('YYYY-MM-DD') : null, newValue ? newValue.format('HH:mm') : null);
                              handleDataChange([index])({target: {data: {scheduleDate: newValue ? newValue.format('YYYY-MM-DD') : null, scheduleTime: null}}});
                            }}
                            onAccept={newValue => {
                              // console.log('onAccept:', newValue ? newValue.format('YYYY-MM-DD') : null, newValue ? newValue.format('HH:mm') : null);
                              handleDataChange([index])({target: {data: {scheduleDate: newValue ? newValue.format('YYYY-MM-DD') : null, scheduleTime: null}}});
                            }}
                          />
                        : <DateTimePicker disableHighlightToday disablePast showDaysOutsideCurrentMonth={false} skipDisabled views={['year', 'month', 'day', 'hours', 'minutes']}
                            label='Data si ora programarii'
                            shouldDisableYear={date => {
                              return !deps.doctorPrograms.find(doctorProgram => true
                                && doctorProgram.idDoctorContract === reception.idDoctorContract
                                && dayjs(doctorProgram.day).year() === /*dayjs(*/date/*)*/.year()
                              );
                            }}
                            shouldDisableMonth={date => {
                              return !deps.doctorPrograms.find(doctorProgram => true
                                && doctorProgram.idDoctorContract === reception.idDoctorContract
                                && dayjs(doctorProgram.day).year() === dayjs(date).year()
                                && dayjs(doctorProgram.day).month() === /*dayjs(*/date/*)*/.month()
                              );
                            }}
                            shouldDisableDate={date => !getDoctorDays(reception.idDoctorContract).includes(dayjs(date).format('YYYY-MM-DD'))}
                            shouldDisableTime={(value, view) => {
                              const program = deps.doctorPrograms.find(doctorProgram =>
                                doctorProgram.idDoctorContract === reception.idDoctorContract &&
                                doctorProgram.day === value.format('YYYY-MM-DD')
                              );

                              if (!program) {
                                // console.log(value.format('YYYY-MM-DD HH:mm'), 'Main - Program nedefinit');
                                return true;
                              }

                              const validScheduleTimes = getValidScheduleTimes(program, reception.scheduleDuration, schedules.filter(schedule => schedule.idDoctorContract === reception.idDoctorContract));

                              if (reception.scheduleTime === '00:00' && validScheduleTimes[0]) {
                                setTimeout(() => {
                                  handleDataChange([index])({target: {data: {scheduleTime: validScheduleTimes[0]}}});
                                }, 0);
                              }

                              return false
                                || (view === 'hours' && !validScheduleTimes.map(time => time.substring(0, 2)).includes(value.format('HH')))
                                || (view === 'minutes' && !validScheduleTimes.includes(value.format('HH:mm')));
                            }}
                            slots={{
                              day: props => {
                                const program = deps.doctorPrograms.find(doctorProgram => doctorProgram.idDoctorContract === reception.idDoctorContract && doctorProgram.day === props.day.format('YYYY-MM-DD'));
                                const scheduled = program ? program.scheduledDuration : 0;
                                const total = program ? program.programDuration : 1;

                                return (
                                  <Tooltip
                                    title={
                                      program
                                        ? <div>
                                            <div>{`Timp ocupat: ${(scheduled / total * 100).toFixed()}%`}</div>
                                            <div style={{position: 'relative', width: '100%', height: '16px', background: 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(255,255,0,1) 50%, rgba(255,0,0,1) 100%)', borderRadius: '4px', overflow: 'hidden'}}>
                                              <div style={{position: 'absolute', top: 0, right: 0, width: `${100 - Math.min(Math.max((scheduled / total) * 100, 0), 100)}%`, height: '100%', background: 'lightgray'}} />
                                            </div>
                                            {program.description &&
                                              <Typography variant='caption'>{program.description}</Typography>
                                            }
                                          </div>
                                        : null
                                    }
                                  >
                                    <Box>
                                      <PickersDay key={props.day.toString()} {...props} outsideCurrentMonth={props.outsideCurrentMonth} day={props.day}
                                        sx={{
                                          fontWeight: 'bold',
                                          borderWidth: '4px',
                                          borderStyle: 'solid',
                                          borderColor: program ? getBadgeColor(program?.scheduledDuration, program?.programDuration) : 'transparent',
                                        }}
                                      />
                                    </Box>
                                  </Tooltip>
                                );
                              },
                            }}
                            slotProps={{
                              textField: {
                                variant: 'outlined',
                                InputProps: {
                                  readOnly: true,
                                  disabled: true,
                                },
                              },
                              field: {
                                clearable: true,
                                onClear: () => handleDataChange([index])({target: {data: {scheduleDate: null, scheduleTime: null}}}),
                              },
                            }}
                            value={reception.scheduleDate && reception.scheduleTime ? dayjs(`${reception.scheduleDate}T${reception.scheduleTime}`) : null}
                            onChange={newValue => {
                              // console.log('onChange:', newValue ? newValue.format('YYYY-MM-DD') : null, newValue ? newValue.format('HH:mm') : null);
                              handleDataChange([index])({target: {data: {scheduleDate: newValue ? newValue.format('YYYY-MM-DD') : null, scheduleTime: newValue ? newValue.format('HH:mm') : null}}});
                            }}
                            onAccept={newValue => {
                              // console.log('onAccept:', newValue ? newValue.format('YYYY-MM-DD') : null, newValue ? newValue.format('HH:mm') : null);
                              handleDataChange([index])({target: {data: {scheduleDate: newValue ? newValue.format('YYYY-MM-DD') : null, scheduleTime: newValue ? newValue.format('HH:mm') : null}}});
                            }}
                          />
                      }

                    </Grid>
                    <Grid item xs={1}>
                      {reception.scheduleDuration !== '0' &&
                        <TextField variant='outlined' select
                          disabled={ac.isLoading}
                          label='Durata'
                          placeholder='Durata'
                          name='scheduleDuration'
                          value={reception.scheduleDuration}
                          onChange={handleDataChange([index])}
                        >
                          {[-15, -10, -5, 0, 5, 10, 15].map(gap => {
                            const totalDuration = reception.services.reduce((acc, val) => acc + +val.duration, 0) + gap;

                            const program = deps.doctorPrograms.find(doctorProgram => doctorProgram.idDoctorContract === reception.idDoctorContract && doctorProgram.day === reception.scheduleDate);

                            const currentStart = dayjs(`${reception.scheduleDate}T${reception.scheduleTime}`);
                            const currentStop = currentStart.add(totalDuration, 'minute');

                            return {
                              gap,
                              duration: totalDuration.toString(),
                              disabled: true
                                && reception.scheduleDate !== null
                                && reception.scheduleTime !== null
                                && (false
                                  || !program // Dacă nu există program definit
                                  || currentStop.isAfter(dayjs(`${program.day}T${program.timeStop}`)) // Depășește programul de lucru
                                  || schedules.some(schedule => { // Se suprapune cu alte programări
                                      const scheduleStart = dayjs(`${schedule.scheduleDate}T${schedule.scheduleTime}`);
                                      const scheduleStop = scheduleStart.add(schedule.scheduleDuration, 'minute');

                                      return (
                                        currentStart.isBetween(scheduleStart, scheduleStop, null, '[)') ||
                                        currentStop.isBetween(scheduleStart, scheduleStop, null, '(]') ||
                                        scheduleStart.isBetween(currentStart, currentStop, null, '[)') ||
                                        scheduleStop.isBetween(currentStart, currentStop, null, '(]')
                                      );
                                    }))
                            };
                          })
                          .filter(item => item.duration >= 10 && item.duration <= 480)
                          .map(item => (
                            <MenuItem key={item.duration}
                              disabled={item.disabled}
                              value={item.duration}
                            >
                              <Typography variant='body1' sx={{/*fontWeight: item.gap === 0 ? 'bold' : 'normal',*/ color: theme => item.gap === 0 ? 'inherit' : theme.palette.warning.dark}}>
                                {item.duration} min
                              </Typography>
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              }
              {
              //#endregion
              }

              {
              //#region Reception services list
              }
              {reception.services.map(service => (
                <Grid key={service.idService} item xs={12}>
                  <Grid container spacing={1}>
                    {
                    //#region Service header
                    }
                    <Grid item xs={9}>
                      <Typography variant='subtitle2' sx={{fontWeight: 'bold', color: theme => service.receptionServiceActionValues.length === 0 ? theme.palette.success.dark : theme.palette.warning.dark}}>
                        {service.service}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{justifyContent: 'center'}}>
                      {props.deps.servicePrices.find(servicePrice => servicePrice.id === service.idServicePrice).idBeneficiaryContract &&
                        <Stack direction='row'>
                          <Tooltip title='Acest serviciu va fi platit de catre partener'>
                            <StoreIcon color={service.isPaidByCustomer ? 'disabled' : 'success'} />
                          </Tooltip>
                          <Switch size='small' color='success'
                            disabled={ac.isLoading}
                            checked={service.isPaidByCustomer}
                            onChange={evt => handleDataChange([index, 'services', {idService: service.idService}])({target: {data: {isPaidByCustomer: evt.target.checked, price: service.priceDefault}}})}
                          />
                          <Tooltip title='Acest serviciu va fi platit de catre client'>
                            <PersonIcon color={service.isPaidByCustomer ? 'success' : 'disabled'} />
                          </Tooltip>
                        </Stack>
                      }
                    </Grid>
                    <Grid item xs={2}>
                      <TextField variant='outlined' required
                        inputProps={{
                          style: {textAlign: 'right'},
                        }}
                        InputProps={{
                          startAdornment: !service.isAutoPrice
                            ? service.price === service.priceDefault
                              ? null
                              : <InputAdornment position='start'>
                                  <Tooltip title='Pretul este modificat de utilizator. Click aici pentru a reveni la pretul implicit'>
                                    <Box>
                                      <IconButton color='warning'
                                        disabled={ac.isLoading}
                                        onClick={() => handleDataChange([index, 'services', {idService: service.idService}])({target: {data: {price: service.priceDefault}}})}
                                      >
                                        <WarningAmberIcon />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                </InputAdornment>
                            : service.price === service.priceDefault
                              ? <InputAdornment position='start'>
                                  <Tooltip title='Pretul este calculat automat ca suma a preturilor serviciilor componente din pachet'>
                                    <Box>
                                      <IconButton color='default'
                                        disabled={true}
                                        onClick={null}
                                      >
                                        <AutoFixHighIcon color='success' />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                </InputAdornment>
                              : <InputAdornment position='start'>
                                  <Tooltip title='Pretul este modificat de utilizator si nu se va recalcula automat in momentul modificarii componentei acestui pachet. Click aici pentru a reveni la pretul implicit'>
                                    <Box>
                                      <IconButton color='warning'
                                        disabled={ac.isLoading}
                                        onClick={() => handleDataChange([index, 'services', {idService: service.idService}])({target: {data: {price: service.priceDefault}}})}
                                      >
                                        <AutoFixHighIcon color='inherit' />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                </InputAdornment>,
                          endAdornment: <InputAdornment position='end'>RON</InputAdornment>,
                        }}
                        disabled={ac.isLoading || (service.isInsuranceHouse && !service.isPaidByCustomer)}
                        color={parseFloat(service.price) !== parseFloat(service.priceDefault) ? 'warning' : 'info'}
                        focused={parseFloat(service.price) !== parseFloat(service.priceDefault)}
                        label={`Pret (${service.priceDefault} RON)`}
                        placeholder={service.priceDefault}
                        name='price'
                        value={service.price}
                        onChange={handleDataChange([index, 'services', {idService: service.idService}])}
                        onBlur={evt => handleDataChange([index, 'services', {idService: service.idService}])({target: {type: 'text', name: 'price', value: evt.target.value.toCurrency('', '', '.', 2)}})}
                      />
                    </Grid>
                    {
                    //#endregion
                    }

                    {
                    //#region Service pack child services
                    }
                    {service.childServices.filter(childService => childService.isPostponable || childService.isRemovable || childService.isAddable).length > 0 &&
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={2} sx={{mt: 1, pb: 1, borderRadius: '4px'}}>
                            <Typography sx={{display: 'block', fontWeight: 'bold'}}>Componenta serviciului:</Typography>
                          </Grid>
                          <Grid item xs={10} sx={{flexWrap: 'wrap', mt: 1, pb: 1, borderRadius: '4px'}}>
                            {service.childServices.sort(firstBy('position')).map(childService => (
                              <Tooltip key={childService.id} title={service.isAutoPrice && getChildServicePrice(reception, service, childService) === null ? 'Acest serviciu nu se poate folosi deoarece nu are setat un pret' : ''}>
                                <Box>
                                  <Chip variant='outlined' size='small' label={childService.service}
                                    sx={{
                                      m: .5,
                                      '& .MuiChip-label': {
                                        textDecoration: childService.isPostponed || childService.isRemoved || (childService.isAddable && !childService.isAdded) || (service.isAutoPrice && getChildServicePrice(reception, service, childService) === null) ? 'line-through' : 'none',
                                      },
                                    }}
                                    disabled={ac.isLoading}
                                    color={
                                      service.isAutoPrice && getChildServicePrice(reception, service, childService) === null
                                        ? 'error'
                                        : childService.isPostponable
                                            ? (childService.isPostponed ? 'secondary' : 'default')
                                            : childService.isRemovable
                                              ? (childService.isRemoved ? 'warning' : 'info')
                                              : childService.isAddable
                                                ? (childService.isAdded ? 'success' : 'warning')
                                                : 'default'
                                    }
                                    deleteIcon={
                                      service.isAutoPrice && getChildServicePrice(reception, service, childService) === null
                                        ? null
                                        : childService.isPostponable
                                            ? (childService.isPostponed ? null : null)
                                            : childService.isRemovable
                                              ? (childService.isRemoved ? <AddCircleOutlineIcon /> : <DeleteIcon />)
                                              : childService.isAddable
                                                ? (childService.isAdded ? <DeleteIcon /> : <AddCircleOutlineIcon />)
                                                : null
                                    }
                                    onDelete={
                                      service.isAutoPrice && getChildServicePrice(reception, service, childService) === null
                                        ? null
                                        : childService.isPostponable
                                            ? () => handleDataChange([index, 'services', {idService: service.idService}, 'childServices', {id: childService.id}])({target: {type: 'component', name: 'isPostponed', value: !childService.isPostponed}})
                                            : childService.isRemovable
                                              ? () => handleDataChange([index, 'services', {idService: service.idService}, 'childServices', {id: childService.id}])({target: {type: 'component', name: 'isRemoved', value: !childService.isRemoved}})
                                              : childService.isAddable
                                                ? () => handleDataChange([index, 'services', {idService: service.idService}, 'childServices', {id: childService.id}])({target: {type: 'component', name: 'isAdded', value: !childService.isAdded}})
                                                : null
                                    }
                                  />
                                </Box>
                              </Tooltip>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                    {
                    //#endregion
                    }

                    {
                    //#region Service action values
                    }
                    {getReceptionServiceActionValues(reception, service).map(entity => (
                      <Grid key={entity.idServiceAction} item xs={parseInt(getServiceAction(entity.idServiceAction).width)}>
                        {getServiceAction(entity.idServiceAction).serviceActionType === 'Text' &&
                          <ServiceActionText
                            disabled={ac.isLoading || entity.isLocked}
                            title={getServiceAction(entity.idServiceAction).title}
                            placeholder={getServiceAction(entity.idServiceAction).placeholder}
                            isRequired={getServiceAction(entity.idServiceAction).requiredStates.includes(getReceptionState(reception))}
                            value={{value: entity.value}}
                            onChange={handleDataChange([index, 'services', {idService: service.idService}, 'receptionServiceActionValues', {idService: entity.idService, idServiceAction: entity.idServiceAction}])}
                          />
                        }
                        {getServiceAction(entity.idServiceAction).serviceActionType === 'Checkbox' &&
                          <ServiceActionCheckbox
                            disabled={ac.isLoading || entity.isLocked}
                            title={getServiceAction(entity.idServiceAction).title}
                            options={deps.datasets.filter(item => item.idParent === getServiceAction(entity.idServiceAction).idDataset)}
                            allowMultiple={getServiceAction(entity.idServiceAction).allowMultiple}
                            isRequired={getServiceAction(entity.idServiceAction).requiredStates.includes(getReceptionState(reception))}
                            value={{value: entity.value}}
                            onChange={handleDataChange([index, 'services', {idService: service.idService}, 'receptionServiceActionValues', {idService: entity.idService, idServiceAction: entity.idServiceAction}])}
                          />
                        }
                        {getServiceAction(entity.idServiceAction).serviceActionType === 'Date' && entity.serviceAction}
                        {getServiceAction(entity.idServiceAction).serviceActionType === 'Option' &&
                          <ServiceActionOption
                            disabled={ac.isLoading || entity.isLocked}
                            title={getServiceAction(entity.idServiceAction).title}
                            options={deps.datasets.filter(item => item.idParent === getServiceAction(entity.idServiceAction).idDataset)}
                            allowMultiple={getServiceAction(entity.idServiceAction).allowMultiple}
                            isRequired={getServiceAction(entity.idServiceAction).requiredStates.includes(getReceptionState(reception))}
                            value={{value: entity.value}}
                            onChange={handleDataChange([index, 'services', {idService: service.idService}, 'receptionServiceActionValues', {idService: entity.idService, idServiceAction: entity.idServiceAction}])}
                          />
                        }
                        {getServiceAction(entity.idServiceAction).serviceActionType === 'Search' && entity.serviceAction}
                        {getServiceAction(entity.idServiceAction).serviceActionType === 'File' &&
                          <ServiceActionFile
                            disabled={ac.isLoading || entity.isLocked}
                            title={getServiceAction(entity.idServiceAction).title}
                            isRequired={getServiceAction(entity.idServiceAction).requiredStates.includes(getReceptionState(reception))}
                            value={{
                              value: entity.value,
                              idFile: entity.idFile,
                              fileName: entity.value,
                              newFile: entity.newFile,
                              newFileName: entity.newFileName,
                              newFileBase64: entity.newFileBase64,
                            }}
                            onChange={handleDataChange([index, 'services', {idService: service.idService}, 'receptionServiceActionValues', {idService: entity.idService, idServiceAction: entity.idServiceAction}])}
                          />
                        }
                        {getServiceAction(entity.idServiceAction).serviceActionType === 'Download' && entity.serviceAction}
                      </Grid>
                    ))}
                    {
                    //#endregion
                    }
                  </Grid>
                </Grid>
              ))}
              {
              //#endregion
              }

              {
              //#region Reception actions
              }
              <Grid item xs={12} sx={{gap: 1, justifyContent: 'space-between', alignItems: 'stretch'}}>
                <Typography
                  sx={{
                    flexGrow: 1,
                    padding: '5px 8px',
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: getReceptionError(reception) ? theme => theme.palette.error.light : 'transparent',
                    borderRadius: '4px',
                  }}
                >
                  {getReceptionError(reception)}
                </Typography>
                <Button
                  disabled={ac.isLoading || Boolean(reception.id) || Boolean(getReceptionError(reception))}
                  onClick={handleReceptionSave(index)}
                >Deschide</Button>
              </Grid>
              {
              //#endregion
              }
            </Grid>
          </DialogContentText>
        ))}
      </DialogContent>
      {
      //#endregion
      }

      {
      //#region Dialog validator content
      }
      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>
      {
      //#endregion
      }

      {
      //#region Dialog actions
      }
      <DialogActions>
        {/* <Button variant={isReady ? 'text' : 'contained'} disabled={ac.isLoading || Boolean(notification) || isReady} onClick={() => setIsReady(true)}>Continua</Button>
        {data.find(reception => reception.type === 'scheduled')
          ? <Button variant={isReady ? 'contained' : 'text'} disabled={ac.isLoading || Boolean(notification) || !isReady} onClick={handleConfirm()}>Programeaza</Button>
          : <Button variant={isReady ? 'contained' : 'text'} disabled={ac.isLoading || Boolean(notification) || !isReady} onClick={handleConfirm()}>Deschide</Button>
        } */}
        <Button variant='contained' disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Deschide</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
      {
      //#endregion
      }
    </Dialog>
  );
};

export default ServiceActionsBeforeOpen;